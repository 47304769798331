<template>
  <div style="margin-left: 10px;margin-right: 10px;margin-top: 10px;height: 100%;position: relative;">
    <div class="but-abs">
      <!-- <el-button size="small" icon="el-icon-plus" style="background-color: #36f;color:white">添加</el-button>
      <el-button size="small" icon="el-icon-delete" style="background-color:#f03040;color:white">删除</el-button>
      <el-button size="small" icon="el-icon-delete" style="background-color:#f03040;color:white">批量删除</el-button> -->
      <el-button size="mini" type="primary" @click="dialogVisible = true">
        <font>添加</font>
      </el-button>
      
    </div>
    <el-card class="box-card" style="height: calc(100% - 96px);">
      <div slot="header" class="el-title clearfix">
        <div>
          <span>运维规划</span>
          <el-button style="float: right; padding: 3px 0" type="text">
            <i class="icon-fullscreen"></i>
          </el-button>
          <!-- <div style="display: flex;margin-left: 63px;align-items: center;position: relative;top: 11px;">
            <div style="color:#293589;">请选择站点 : </div>
            <el-select size="mini" v-model="zhanvalue" :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)" style="width: 170px;">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.fullname"
                :value="item.indexs">
              </el-option>
            </el-select>
          </div> -->
        </div>
        <div class="text item" style="margin:-10px -10px;">
          <div>
            <div class="calender">
              <div style="display: flex;flex-wrap: wrap;justify-content: center;" class="year">
                <!-- <div class="type">
                  <div class="type-g" v-for="(item8,index6) in bbb" :key="index6"  @click="type = index6" :class="{borders:index6 == 11,right:index6 == 11,blue:type == index6}">
                    {{item8.fullname}}
                  </div>
                </div> -->
                <div style="width: calc(94% - 60px);">
                  <div class="buttons">
                    <div class="date">2021年</div>
                    <div class="date1">2022年</div>
                    <div class="date2">2023年</div>
                  </div>
                  <table cellspacing="0" cellpadding="0" class="table">
                    <div class="montht" v-for="(item1,index1) in weekDays" :key="index1">
                      <div class="montht-title">{{item1.title}}</div>
                      <div class="montht-year" v-for="(item2,index2) in item1.name" :key="'c'+index2">
                        <div class="montht-month" v-for="(item3,index5) in item1.name[index2]" :key="'c'+index5" :class="{borders:index5 == 11 && index2 !== 2,right:index5 == 11 && index2 !== 0,}">{{item3}}</div>
                      </div>
                    </div>
                    <div :style="{maxHeight: fullHeight,width: 'calc(100% + 34px)'}">
                        <div class="line" v-for="(item5,index) in options" :key="'b'+index" :class="{colors:index%2 == 1,down:index == options.length-1}">
                          <div :title="item5.fullname" class="line-title" >{{item5.fullname}}</div>
                          <div class="line-year">
                            <div class="line-month" v-for="(item3,index4) in item5.TimeList" :key="'a'+index4" :class="{borders:index4 == 11 || index4 == 23,blueBorder:item3.NextDate == blueDay && item5.fullname == blueplant}" >
                              <div v-if="item3.plans.length != 0" @click="showmore(item3.plans)">
                                维保
                              </div>
                            </div>
                            
                          </div>
                          <!-- <div class="outline" style="">
                            <div class="outline1" @click="editClick(item5)">
                              <i class="el-icon-edit-outline" style="height: 14px;"></i>
                            </div>
                          </div> -->
                        </div>
                    </div>
                  </table>
                  <!-- <i class="el-icon-edit-outline"></i> -->
                </div>
                
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <!-- <customizationComponents ref="customizationComponents"></customizationComponents> -->
    <el-dialog title="修改时间" :visible.sync="dialogTableVisible">
      <el-form ref="tableForm" inline-message :model="dataInfo" class="demo-user" label-position="right" style="margin-bottom: 30px;justify-content: center;display: flex;align-items: center;">
        <el-form-item label="计划时间 : " prop="DESCRIPTION" label-width="84px" style="width:226px">
          <el-date-picker
            v-model="time1"
            type="month"
            format="yyyy-MM"
            value-format="yyyy-MM"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-button @click="changeTime" style="height: 30px;margin-bottom: 5px;margin-left: 15px;" type="primary" size="mini">确定</el-button>
      </el-form>
    </el-dialog>
    <el-dialog title="详细信息" :visible.sync="dialogTableVisible2">
      <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-row :gutter="0">    
              <el-col :span="6">
                <el-form-item label="运维主管">
                  <span>{{ props.row.lead_nickname }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="运维工程师">
                  <span>{{ props.row.contact_nickname }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="频率单位">
                  <span>{{ props.row.FREQUNIT }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="频率">
                  <span>{{ props.row.FREQUENCY }}</span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="0">
              <el-col :span="24">
                <el-form-item label="维保描述" prop="CONTENT" label-width="130px">
                    <el-input :disabled="true" type="textarea" :rows="4" style="width:100%" v-model="props.row.DESCRIPTION" placeholder="请输入"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        width="80"
        prop="status">
      </el-table-column>
      <!-- <el-table-column
        label="维保描述"
        width="150"
        prop="DESCRIPTION">
      </el-table-column> -->
      <el-table-column
        label="设备类型"
        width="200"
        prop="ASSETNUM">
      </el-table-column>
      <el-table-column
        label="开始时间"
        prop="CONCRETESTARTTIME">
      </el-table-column>
      <el-table-column
        label="结束时间"
        prop="CONCRETEENDTIME">
      </el-table-column>
      <el-table-column
          prop="ALARMLEVEL"
          width="90"
          label="操作"
          >
      <template slot-scope="props">
        <el-button title="修改" type="text" size="small" style="padding: 0;" @click="editClick(props.row)">
          <i class="el-icon-edit" style="color:rgb(51, 102, 255);"></i>
        </el-button>
        <el-button title="删除" type="text" size="small" @click="delpmApp(props.row.id)">
          <i class="el-icon-delete" style="color:red;"></i>
        </el-button>
      </template>
      </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="维保信息" :visible.sync="dialogVisible" width="80%">
            <el-form ref="tableForm" inline-message :model="dataInfo" class="demo-user" label-position="right">
                
                  
                    <el-row :gutter="0">
                        <!-- <el-col :span="8">
                            <el-form-item label="维保描述" prop="DESCRIPTION" label-width="100px">
                                <el-input :disabled="disable" v-model="dataInfo.DESCRIPTION" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col> -->
                        <!-- <el-col :span="8">
                        	<el-form-item label="变电站名称" prop="deptid" label-width="100px">
															<el-input style="width:100%" v-model="bdzname" disabled :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
																	<i slot="suffix" class="el-icon-circle-close input-suffix-icon"
																			@click="clearInput(dataInfo,'deptid')" v-show="!!dataInfo.deptid"></i>
																	<el-button slot="append" icon="el-icon-search" @click="choose4(dataInfo,bdzchooseParam,{prop:'deptid'})"></el-button>
															</el-input>
													</el-form-item>
                        </el-col> -->
                        <el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                        	<el-form-item label="设备类型" prop="deptid"  style="width: 100%;">
															<el-cascader
																ref="myCascader"
																v-model="dataInfo.ASSETTYPE"
																:props="{value:'id',label:'name',children:'children',checkStrictly: true,emitPath:false}"
																:options="options"
																:show-all-levels="false"
																@change="changecascader"
                                style="width: 100%;"
															></el-cascader>
													</el-form-item>
                        </el-col>
												<el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
														<el-form-item label="所属变电站" prop="dept_fullname" >
																<el-input style="width:100%" v-model="dataInfo.dept_fullname" disabled :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
																		<i slot="suffix" class="el-icon-circle-close input-suffix-icon"
																				@click="clearInput(dataInfo,'dept_fullname')" v-show="!!dataInfo.dept_fullname"></i>
																		<el-button slot="append" icon="el-icon-search" @click="choose(dataInfo,deptidchooseParam,{prop:'dept_fullname'})"></el-button>
																</el-input>
														</el-form-item>
												</el-col>
												<el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                            <el-form-item label="运维主管" prop="LEADER" >
																<el-input style="width:100%" v-model="dataInfo.LEADERname" disabled :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
																		<i slot="suffix" class="el-icon-circle-close input-suffix-icon"
																				@click="clearInput(dataInfo,'LEADERname')" v-show="!!dataInfo.LEADERname"></i>
																		<el-button slot="append" icon="el-icon-search" @click="choose3(dataInfo,peoplechooseParam,{prop:'LEADERname'})"></el-button>
																</el-input>
                            </el-form-item>
                        </el-col>
												<el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                        	<el-form-item label="运维工程师" prop="OPERATORname" >
															<el-input style="width:100%" v-model="dataInfo.OPERATORname" disabled :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
																	<i slot="suffix" class="el-icon-circle-close input-suffix-icon"
																			@click="clearInput(dataInfo,'OPERATORname')" v-show="!!dataInfo.OPERATORname"></i>
																	<el-button slot="append" icon="el-icon-search" @click="choose5(dataInfo,peoplechooseParam2,{prop:'OPERATORname'})"></el-button>
															</el-input>
													</el-form-item>
                        </el-col>
                      </el-row>
											
											<el-row :gutter="0">
                        
                        <el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                        	<el-form-item label="频率单位" prop="FREQUNIT" >
															<el-select v-model="dataInfo.FREQUNIT" :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
																<el-option
																		v-for="item in frequnit"
																		:key="item.code"
																		:label="item.name"
																		:value="item.name">
																</el-option>
															</el-select>
													</el-form-item>
                        </el-col>
												<el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                            <el-form-item label="频率" prop="FREQUENCY" >
                                <el-input :disabled="disable" v-model="dataInfo.FREQUENCY" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
												<el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                        	<el-form-item label="开始时间" prop="CONCRETESTARTTIME" >
															<el-date-picker  v-model="dataInfo.CONCRETESTARTTIME" placeholder="请选择时间" type="datetime" style="width:100%" value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
													</el-form-item>
                        </el-col>
												<el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                        	<el-form-item label="结束时间" prop="CONCRETEENDTIME" >
															<el-date-picker  v-model="dataInfo.CONCRETEENDTIME" placeholder="请选择时间" type="datetime" style="width:100%" value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
													</el-form-item>
                        </el-col>
                      </el-row>
											
											<el-row :gutter="0">
												<el-col :span="24" style="padding-left: 10px;">
													<el-form-item label="维保描述" prop="DESCRIPTION" >
															<el-input type="textarea" :rows="4" style="width:100%" v-model="dataInfo.DESCRIPTION" placeholder="请输入"></el-input>
													</el-form-item>
												</el-col>
											</el-row>
                    
								
                    <!-- <el-collapse-item title="频率" name="2">
                        <el-row :gutter="0">
													<el-col :span="8">
                              <el-form-item :disabled="disable" label="执行人" prop="EXECTOR" label-width="100px">
                                  <el-input style="width:100%" v-model="zxr" disabled :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
                                      <i slot="suffix" class="el-icon-circle-close input-suffix-icon"
                                          @click="clearInput(dataInfo,'EXECTOR')" v-show="!!dataInfo.EXECTOR"></i>
                                      <el-button slot="append" icon="el-icon-search" @click="choose5(dataInfo,zxrchooseParam,{prop:'EXECTOR'})"></el-button>
                                  </el-input>
                              </el-form-item>
                        	</el-col>
													<el-col :span="8">
                              <el-form-item :disabled="disable" label="负责人" prop="LEADER" label-width="100px">
                                  <el-input style="width:100%" v-model="fzr" disabled :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
                                      <i slot="suffix" class="el-icon-circle-close input-suffix-icon"
                                          @click="clearInput(dataInfo,'LEADER')" v-show="!!dataInfo.LEADER"></i>
                                      <el-button slot="append" icon="el-icon-search" @click="choose3(dataInfo,peoplechooseParam,{prop:'LEADER'})"></el-button>
                                  </el-input>
                              </el-form-item>
                        	</el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="规划子表" name="3">
                        <el-button style="float: right;margin-bottom: 20px;" type="primary" size="small" @click="addplant">添加计划</el-button>
                        <el-table
                            :data="tableData"
                            :expand-row-keys="tableDataid"
                            row-key="fakeid"
                            >
                            <el-table-column type="expand">
                              <template slot-scope="props">
                                <el-form ref="plantForm" inline-message  class="demo-user" label-position="right">
                                  <el-col :span="5" style="margin-right:50px">
                                      <el-form-item label="描述" prop="DESCRIPTION" label-width="80px">
                                          <el-input v-model="props.row.DESCRIPTION" placeholder="请输入"></el-input>
                                      </el-form-item>
                                  </el-col>
                                  <el-col :span="2" style="margin-right:72px">
																		<el-form-item label="频率单位" prop="FREQUNIT" label-width="100px">
                                    <el-select v-model="props.row.FREQUNIT" :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
																			<el-option
																					v-for="item in frequnit"
																					:key="item.code"
																					:label="item.name"
																					:value="item.name">
																			</el-option>
																		</el-select>
																		</el-form-item>
                                  </el-col>
                                  <el-col :span="2" style="margin-right:80px">
                                      
																				<el-form-item label="频率" prop="FREQUENCY" label-width="100px">
                                        <el-input v-model="props.row.FREQUENCY" placeholder="请输入"></el-input>
                                    	</el-form-item>
																				
                                
                                  </el-col>
                         
                                  <el-col :span="2" style="margin-right:50px">
                                    <el-form-item label="规划类型" prop="PLANTYPE" label-width="100px">
                                        <el-select v-model="props.row.PLANTYPE" :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
                                          <el-option
                                            v-for="item in assetplantype"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.id">
                                          </el-option>
                                        </el-select>
                                    </el-form-item>
                                  </el-col>
                                </el-form>
                              </template>
                            </el-table-column>
                            <el-table-column
                                prop="DESCRIPTION"
                                label="描述"
                                width="250"
                                >
                            </el-table-column>
														<el-table-column
                                prop="FREQUNIT"
                                label="频率单位"
                                width="200"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="FREQUENCY"
                                label="频率"
                                width="200"
                                >
                            </el-table-column>
                            
                            <el-table-column
                                prop="PLANTYPE"
                                label="规划类型"
                                width="200"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="ALARMLEVEL"
                                label="操作"
                                >
                            <template slot-scope="scope">
                              <el-button title="删除" type="text" size="small" @click="del(scope)">
                                <i class="el-icon-delete" style="color:red;"></i>
                              </el-button>
                            </template>
                            </el-table-column>
                        </el-table>
                    </el-collapse-item>
                </el-collapse> -->
            </el-form>
            <span slot="footer" class="dialog-footer" v-if="type == 1">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveBtn(1)">保存</el-button>
            </span>
						<span slot="footer" class="dialog-footer" v-else>
                <el-button type="primary" @click="saveBtn(0)">修改本次</el-button>
                <el-button type="primary" @click="saveBtn(1)">全部修改</el-button>
            </span>
        </el-dialog>
  </div>
</template>

<script>

// import Config from "@/config.js";
import { Loading } from "element-ui";
import * as utils from './index'
import customizationComponents from "@/component/operationPlanning/operation.vue"  //客户化处理组件
export default {
  name: "",
  components: {customizationComponents},
  props: {
    value: {
      type: Date,
      default: () => new Date()
    },
    businessList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialogVisible:false,
      PMMONTH:"",
      blueDay:"",
      blueplant:"",
      id:"",
      time1:"",
      dataInfo:{},
      dialogTableVisible2:false,
      dialogTableVisible:false,
      zhanvalue:0,
      options:[],
      fullHeight: document.documentElement.clientHeight - 278 + 'px',
      basic_url: Config.dev_url,
      type:0,
      timeData:[],
      dataList:[],
      weekDays:[{
      title:"",
      name:[["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"],
      ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"],
      ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"],]}],
      bbb:[],
      visibleDays:[],
      visibleWeek:[],
      visiblequarter:[],
      visibleyear:[],
      selectData:[],
      tableData: []
    };
  },
  computed: {
  
  },
  methods: {
    //删除计划
    delpmApp(id){
      var url = this.basic_url + `/api-apps/app/pmApp/${id}?ISALLCHANGE=1`;
        this.$axios.delete(url,{id:this.id,NextDate:this.time1}).then((res) => {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.dialogTableVisible2 = false
          setTimeout(() => {
            this.getData()
          }, 200);
        });
    },
    changeTime(){
      this.dialogTableVisible = false

      if(this.time1){
        var url = this.basic_url + `/api-apps/appCustom/updatePlanDate`;
        this.$axios.post(url,{id:this.id,NextDate:this.time1}).then((res) => {
          this.getData()
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          });
        });
      }
      this.time1 = ""
    },
    showmore(item){
      this.tableData = item
      this.dialogTableVisible2 = true
    },
    show(day,name,item){
      if(day == this.blueDay && name == this.blueplant){
        this.blueplant = ""
        this.blueDay = ""
      }else{
        this.blueplant = name
        this.blueDay = day
      }
      this.PMMONTH = item.NextDate
      
    },
    shuaxin(){
      setTimeout(() => {
            this.getData()
          }, 200);
    },
    getBut(){
      var url = this.basic_url + `/api-user/permissions/getPermissionByRoleIdAndSecondMenu?menuId=430&roleId=${this.$store.state.roleid}`;
        this.$axios.get(url).then((res) => {
          
          this.$store.dispatch("setButtonsAct", res.data);
          console.log(this.$store.state.buttons)
        });
    },
    editClick(item) {
      
      this.$refs.customizationComponents.customOperationEdit("edit",item)
    },
    
    btnClick(item,PMMONTH) {
      console.log(PMMONTH)
      // if (item.eventmethod == 'customAssetInfoAdd') {
        this.$refs.customizationComponents.customOperationAdd("element",item,PMMONTH)
      // } else if (item.eventmethod == 'delBtn') {
      //   this.delBtn()
      // } else if (item.eventmethod == 'batchDelBtn') {
      //   this.batchDelBtn()
      // }
    },
    delBtn() {
      if (this.selectData.length == 0) {
        this.$message.warning("请先选择数据再进行操作");
        return;
      } else if (this.selectData.length > 1) {
        this.$message.warning("最多只能选择一条数据进行操作");
        return;
      }
      var id = this.selectData[0].id || this.selectData[0].ID;
      var url = this.basic_url + "/api-apps/app/assetApp/" + id
      this.$confirm("确定删除此数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(({ value }) => {
        this.loadingObj.loading = true
        this.$axios.delete(url).then(res => {
          if (res.data.resp_code == 0) {
            this.loadingObj.loading = false
            this.$message.success("删除成功");
            this.$refs.list.getList()
            this.$store.dispatch('setTableSelectStatusAct', true);
          } else {
            this.loadingObj.loading = false
            this.$message.warning(res.data.resp_msg);
          }
        });
      });
    },
    batchDelBtn() {
      if (this.selectData.length == 0) {
        this.$message.warning("请先选择数据再进行操作");
        return;
      } else {
        var ids = [];
        for (var i = 0; i < this.selectData.length; i++) {
          if (!!this.selectData[i].id) {
            ids.push(this.selectData[i].id)
          }
        }
        var url = this.basic_url + "/api-apps/app/assetApp/deletes?ids=" + ids.join(',');
        this.$confirm("确定删除选中数据吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(({ value }) => {
          this.loadingObj.loading = true
          this.$axios.delete(url).then(res => {
            if (res.data.resp_code == 0) {
              this.loadingObj.loading = false
              this.$message.success("删除成功");
              this.$store.dispatch('setTableSelectStatusAct', true);
              this.$refs.list.getList()
            } else {
              this.loadingObj.loading = false
              this.$message.warning(res.data.resp_msg);
            }
          });
        });
      }
    },
    changeMapStatus(){
      this.mapStatus = !this.mapStatus;
      this.$nextTick(()=>{
        if (this.mapStatus) {
          this.$refs.mapViews.view(this.lnglatlist)
        } else {
          this.$refs.mapViewsBig.view(this.lnglatlist)
        }
      })
    },
    getData(){
      this.options = []
      // var url = this.basic_url + `/api-apps/appCustom/getStationYw`;
      //   this.$axios.post(url,).then((res) => {
        let data = [{"deptid":14,"fullname":"厦门电表分公司","TimeList":[]},{"deptid":40,"fullname":"厦门ABB开关有限公司","TimeList":[{"LOCATION":0,"ROUTE":0,"PMNUM":"PM2022530643","ISALLCHANGE":"1","LEADTIMEACTIVE":null,"ASSETTYPE":"1","deptid":"40","FREQUNIT":"月","LEADER":"1048","DESCRIPTION":null,"FREQUENCY":"3","id":532239,"SIGN":532230,"TYPE":null,"LEADTIME":0,"createuserdesc":"超级管理员","CONCRETESTARTTIME":"2023-12-14 00:00:00","WONUM":null,"lead_nickname":"超级管理员","createtime":"2022-01-01 01:01:00","EXECTOR":null,"CONCRETEENDTIME":"2023-12-18 00:00:00","createuser":"1048","version":"1","ASSETNUM":"低压开关设备","NEXTDATE":null,"OPERATOR":1057,"contact_nickname":"奥利维亚","updatetime":null,"status":"等待处理","updateuser":null}]},{"deptid":41,"fullname":"昆山总部","TimeList":[]}]
          let f = 0
            for(let item1 of data){
              let a = {}
              let bb = {}
              
              bb['fullname'] = item1.fullname
              bb['indexs'] = f
              bb['TimeList'] = item1.TimeList
              
              a['DescList'] = []
              this.options.push(bb)
              let b = {}
              b['TimeList'] = []
              for(let i =0;i<3;i++){
                for(let j =1;j<13;j++){
                  let c = {}
                  let d = []
                  if(j<10){
                    c['NextDate'] = 2021+i+"-0"+j
                  }else{
                    c['NextDate'] = 2021+i+"-"+j
                  }
                  c['plans'] = []
                  b['TimeList'].push(c)
                  
                }
              }

              
              
              for(let item3 in b["TimeList"]){
                for(let item4 in item1.TimeList){
                    if(item1.TimeList[item4]){
                      
                      if(item1.TimeList[item4].CONCRETESTARTTIME){
                        
                        if(b["TimeList"][item3].NextDate == item1.TimeList[item4].CONCRETESTARTTIME.substring(0, 7)){
                          
                          b["TimeList"][item3].plans.push(item1.TimeList[item4])
                          // b["TimeList"][item3]['PlanType'] = item1.TimeList[index].PlanType
                          // b["TimeList"][item3]['id'] = item1.TimeList[index].id
                          // console.log(index < item1.TimeList.length - 1)
                        }
                      }else{
                      
                    }
                  }else{
                    
                  }
                }
              }
              this.options[f].TimeList = b['TimeList']
              f = f + 1
              
              
            }
            // this.bbb.push(a)
          
        // });
    }
  },
  mounted(){
    // this.getBut()
    this.getData()
  }
};
</script>

<style scoped>
  .buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
  }
  .date{
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    width: 33%;
    margin-left:8%;
  }
  .date1{
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    width: 33%;
    margin-left: 23%;
  }
  .date2{
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    width: 33%;
    margin-left: 23%;
    margin-right: 23%;
  }
  .type{
    display: flex;
    width: 4%;
    margin-top: 60px;
    flex-direction: column;
    border-radius: 5px;
    text-align: center;
    margin-left: 20px;
  }
  .type-g{
    font-size: 14px;
    color: #999999;
    flex: 1;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    height: 34.5px;
    padding-left: 3px;
    padding-right: 9px;
    border: #CCCCCC solid 1px;
    border-radius: 4px 0px 0px 4px;
    border-right: 0px;
    display: flex;
    align-items: center;
  }
  .blue{
    color: #3366FF;
    width: 120%;
    margin-left: -11px;
    background-color: #f9faff;
    border: #3366FF solid 1px;
  }
  .calender{
    margin-bottom: 20px;
    margin-top: 20px;
  }
  /* ----------------------------------- */
  
  .tabless{
    display: flex;
  }
  .table{
    width: 100%;
    height: 100px;
    
    
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.03);
  }
  .line{
    display: flex;
    height: 40px;
    position: relative;
    width: calc(100% - 34px);
  }
  .line-title{
    overflow: hidden;
    width: 8.05%;
    padding-left: 5px;
    height: 40px;
    line-height: 40px;
    border-left: #DDDDDD solid 1px;
    border-right: #DDDDDD solid 1px;
    font-size: 12px;
  }
  .line-year{
    display: flex;
    text-align: center;
    width: 100%;
    height: 40px;
    line-height: 40px;
  }
  .line-month{
    flex: 1;
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-left: #eeeeee solid 0.1px;
    border-right: #eeeeee solid 0.1px;
    font-size: 10px;
    color: #999999;
    cursor: pointer;
}
.montht{
  display: flex;
  height: 26px;
  background-color:#f9faff;
}
.montht-title{
  width: 28.7%;
  height: 26px;
  border: #dddddd solid 1px;
}
.montht-year{
  display: flex;
  text-align: center;
  width: 120%;
  height: 26px;
  line-height: 26px;
}
.montht-month{
  display: flex;
  flex: 1;
  text-align: center;
  width: 100%;
  height: 26px;
  line-height: 26px;
  border-left: #eeeeee solid 0.1px;
  border-right: #eeeeee solid 0.1px;
  border-top: #dddddd solid 1px;
  border-bottom: #dddddd solid 1px;
  font-size: 10px;
  color: #999999;
  justify-content: center;
}
.table-l{
  border: 0.1px solid #DDDDDD;
  width: 100%;
}
.borders{
  border-right: rgba(170,170,170,1) solid 0.1px;
}
.outline{
    display: flex;
    height: 40px;
    position: absolute;
    right: -33px;
    z-index: 999;
}
.box-shadow{
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.03);
}
.outline1{
  display: flex;
  flex: 1;
  height: 40px;
      padding-left: 10px;
    padding-right: 10px;

  background-color: #F7F7F7;
  position: relative;
  left: 1px;
  cursor: pointer;
      align-items: center;
}
.colors{
  background-color:rgba(248, 247, 247, 0.918);
}
.right{
  position: relative;
  right: -0.5px;
}
.down{
  border-bottom:rgba(225, 225, 225) solid 0.1px ;
}
.but-abs{
    position: absolute;
    top: 20px;
    right: 15px;
}
.blueBorder{
  border: 1px solid rgb(51, 102, 255);
}
/deep/.box-card .el-card__header{
      border-bottom: 0px solid #EBEEF5;
}
</style>

