<template>
    <div>
        <el-dialog title="维保信息" :visible.sync="dialogVisible" width="80%">
            <el-form ref="tableForm" inline-message :model="dataInfo" class="demo-user" label-position="right">
                <el-collapse v-model="activeNames">
                   <el-collapse-item title="" name="1">
                    <el-row :gutter="0">
                        <!-- <el-col :span="8">
                            <el-form-item label="维保描述" prop="DESCRIPTION" label-width="100px">
                                <el-input :disabled="disable" v-model="dataInfo.DESCRIPTION" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col> -->
                        <!-- <el-col :span="8">
                        	<el-form-item label="变电站名称" prop="deptid" label-width="100px">
															<el-input style="width:100%" v-model="bdzname" disabled :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
																	<i slot="suffix" class="el-icon-circle-close input-suffix-icon"
																			@click="clearInput(dataInfo,'deptid')" v-show="!!dataInfo.deptid"></i>
																	<el-button slot="append" icon="el-icon-search" @click="choose4(dataInfo,bdzchooseParam,{prop:'deptid'})"></el-button>
															</el-input>
													</el-form-item>
                        </el-col> -->
                        <el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                        	<el-form-item label="设备类型" prop="deptid"  style="width: 100%;">
															<el-cascader
																ref="myCascader"
																v-model="dataInfo.ASSETTYPE"
																:props="{value:'id',label:'name',children:'children',checkStrictly: true,emitPath:false}"
																:options="options"
																:show-all-levels="false"
																@change="changecascader"
                                style="width: 100%;"
															></el-cascader>
													</el-form-item>
                        </el-col>
												<el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
														<el-form-item label="所属变电站" prop="dept_fullname" >
																<el-input style="width:100%" v-model="dataInfo.dept_fullname" disabled :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
																		<i slot="suffix" class="el-icon-circle-close input-suffix-icon"
																				@click="clearInput(dataInfo,'dept_fullname')" v-show="!!dataInfo.dept_fullname"></i>
																		<el-button slot="append" icon="el-icon-search" @click="choose(dataInfo,deptidchooseParam,{prop:'dept_fullname'})"></el-button>
																</el-input>
														</el-form-item>
												</el-col>
												<el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                            <el-form-item label="运维主管" prop="LEADER" >
																<el-input style="width:100%" v-model="dataInfo.LEADERname" disabled :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
																		<i slot="suffix" class="el-icon-circle-close input-suffix-icon"
																				@click="clearInput(dataInfo,'LEADERname')" v-show="!!dataInfo.LEADERname"></i>
																		<el-button slot="append" icon="el-icon-search" @click="choose3(dataInfo,peoplechooseParam,{prop:'LEADERname'})"></el-button>
																</el-input>
                            </el-form-item>
                        </el-col>
												<el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                        	<el-form-item label="运维工程师" prop="OPERATORname" >
															<el-input style="width:100%" v-model="dataInfo.OPERATORname" disabled :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
																	<i slot="suffix" class="el-icon-circle-close input-suffix-icon"
																			@click="clearInput(dataInfo,'OPERATORname')" v-show="!!dataInfo.OPERATORname"></i>
																	<el-button slot="append" icon="el-icon-search" @click="choose5(dataInfo,peoplechooseParam2,{prop:'OPERATORname'})"></el-button>
															</el-input>
													</el-form-item>
                        </el-col>
                      </el-row>
											
											<el-row :gutter="0">
                        
                        <el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                        	<el-form-item label="频率单位" prop="FREQUNIT" >
															<el-select v-model="dataInfo.FREQUNIT" :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
																<el-option
																		v-for="item in frequnit"
																		:key="item.code"
																		:label="item.name"
																		:value="item.name">
																</el-option>
															</el-select>
													</el-form-item>
                        </el-col>
												<el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                            <el-form-item label="频率" prop="FREQUENCY" >
                                <el-input :disabled="disable" v-model="dataInfo.FREQUENCY" placeholder="请输入"></el-input>
                            </el-form-item>
                        </el-col>
												<el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                        	<el-form-item label="开始时间" prop="CONCRETESTARTTIME" >
															<el-date-picker  v-model="dataInfo.CONCRETESTARTTIME" placeholder="请选择时间" type="datetime" style="width:100%" value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
													</el-form-item>
                        </el-col>
												<el-col :span="6" style="padding-left: 10px;padding-right: 10px;">
                        	<el-form-item label="结束时间" prop="CONCRETEENDTIME" >
															<el-date-picker  v-model="dataInfo.CONCRETEENDTIME" placeholder="请选择时间" type="datetime" style="width:100%" value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
													</el-form-item>
                        </el-col>
                      </el-row>
											
											<el-row :gutter="0">
												<el-col :span="24" style="padding-left: 10px;">
													<el-form-item label="维保描述" prop="DESCRIPTION" >
															<el-input type="textarea" :rows="4" style="width:100%" v-model="dataInfo.DESCRIPTION" placeholder="请输入"></el-input>
													</el-form-item>
												</el-col>
											</el-row>
                    </el-collapse-item> 
								</el-collapse>
                    <!-- <el-collapse-item title="频率" name="2">
                        <el-row :gutter="0">
													<el-col :span="8">
                              <el-form-item :disabled="disable" label="执行人" prop="EXECTOR" label-width="100px">
                                  <el-input style="width:100%" v-model="zxr" disabled :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
                                      <i slot="suffix" class="el-icon-circle-close input-suffix-icon"
                                          @click="clearInput(dataInfo,'EXECTOR')" v-show="!!dataInfo.EXECTOR"></i>
                                      <el-button slot="append" icon="el-icon-search" @click="choose5(dataInfo,zxrchooseParam,{prop:'EXECTOR'})"></el-button>
                                  </el-input>
                              </el-form-item>
                        	</el-col>
													<el-col :span="8">
                              <el-form-item :disabled="disable" label="负责人" prop="LEADER" label-width="100px">
                                  <el-input style="width:100%" v-model="fzr" disabled :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
                                      <i slot="suffix" class="el-icon-circle-close input-suffix-icon"
                                          @click="clearInput(dataInfo,'LEADER')" v-show="!!dataInfo.LEADER"></i>
                                      <el-button slot="append" icon="el-icon-search" @click="choose3(dataInfo,peoplechooseParam,{prop:'LEADER'})"></el-button>
                                  </el-input>
                              </el-form-item>
                        	</el-col>
                        </el-row>
                    </el-collapse-item>
                    <el-collapse-item title="规划子表" name="3">
                        <el-button style="float: right;margin-bottom: 20px;" type="primary" size="small" @click="addplant">添加计划</el-button>
                        <el-table
                            :data="tableData"
                            :expand-row-keys="tableDataid"
                            row-key="fakeid"
                            >
                            <el-table-column type="expand">
                              <template slot-scope="props">
                                <el-form ref="plantForm" inline-message  class="demo-user" label-position="right">
                                  <el-col :span="5" style="margin-right:50px">
                                      <el-form-item label="描述" prop="DESCRIPTION" label-width="80px">
                                          <el-input v-model="props.row.DESCRIPTION" placeholder="请输入"></el-input>
                                      </el-form-item>
                                  </el-col>
                                  <el-col :span="2" style="margin-right:72px">
																		<el-form-item label="频率单位" prop="FREQUNIT" label-width="100px">
                                    <el-select v-model="props.row.FREQUNIT" :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
																			<el-option
																					v-for="item in frequnit"
																					:key="item.code"
																					:label="item.name"
																					:value="item.name">
																			</el-option>
																		</el-select>
																		</el-form-item>
                                  </el-col>
                                  <el-col :span="2" style="margin-right:80px">
                                      
																				<el-form-item label="频率" prop="FREQUENCY" label-width="100px">
                                        <el-input v-model="props.row.FREQUENCY" placeholder="请输入"></el-input>
                                    	</el-form-item>
																				
                                
                                  </el-col>
                         
                                  <el-col :span="2" style="margin-right:50px">
                                    <el-form-item label="规划类型" prop="PLANTYPE" label-width="100px">
                                        <el-select v-model="props.row.PLANTYPE" :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)">
                                          <el-option
                                            v-for="item in assetplantype"
                                            :key="item.code"
                                            :label="item.name"
                                            :value="item.id">
                                          </el-option>
                                        </el-select>
                                    </el-form-item>
                                  </el-col>
                                </el-form>
                              </template>
                            </el-table-column>
                            <el-table-column
                                prop="DESCRIPTION"
                                label="描述"
                                width="250"
                                >
                            </el-table-column>
														<el-table-column
                                prop="FREQUNIT"
                                label="频率单位"
                                width="200"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="FREQUENCY"
                                label="频率"
                                width="200"
                                >
                            </el-table-column>
                            
                            <el-table-column
                                prop="PLANTYPE"
                                label="规划类型"
                                width="200"
                                >
                            </el-table-column>
                            <el-table-column
                                prop="ALARMLEVEL"
                                label="操作"
                                >
                            <template slot-scope="scope">
                              <el-button title="删除" type="text" size="small" @click="del(scope)">
                                <i class="el-icon-delete" style="color:red;"></i>
                              </el-button>
                            </template>
                            </el-table-column>
                        </el-table>
                    </el-collapse-item>
                </el-collapse> -->
            </el-form>
            <span slot="footer" class="dialog-footer" v-if="type == 1">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveBtn(1)">保存</el-button>
            </span>
						<span slot="footer" class="dialog-footer" v-else>
                <el-button type="primary" @click="saveBtn(0)">修改本次</el-button>
                <el-button type="primary" @click="saveBtn(1)">全部修改</el-button>
            </span>
        </el-dialog>
        <el-dialog title="维保子表" :visible.sync="dialogVisible2" width="50%">
        </el-dialog>
        <!-- <vchoose ref="choose" :chooseParam="chooseParam" @updateChoose="updateChoose" @updateChooseData="updateChooseData"></vchoose>
        <vchoose ref="sb" :chooseParam="chooseParam" @updateChoose="updateChoose" @updateChooseData="updateChooseData2"></vchoose>
        <vchoose ref="fzr" :chooseParam="chooseParam" @updateChoose="updateChoose" @updateChooseData="updateChooseData3"></vchoose>
				<vchoose ref="bdz" :chooseParam="chooseParam" @updateChoose="updateChoose" @updateChooseData="updateChooseData4"></vchoose>
				<vchoose ref="zxr" :chooseParam="chooseParam" @updateChoose="updateChoose" @updateChooseData="updateChooseData5"></vchoose> -->
    </div>
</template>

<script>
// import Config from '@/config.js'
// import { Loading } from 'element-ui'
// import vchoose from "@/components/plugin/tablechoose/datachoose.vue";
// import uuidv1 from 'uuid/v1';//引入uuid文件
export default {
    name:'workorder',
    // components:{ vchoose},
    data(){
        return{
						
						options:[],
						PMMONTH:"",
		    		type:1,
            disable:false,
            tableData:[],
            tableDataid:[],
						dialogVisible2:false,
            dialogVisible:false,
            activeNames:['1','2','3','4','5'],
            plantInfo:{},
            dataInfo:{
              TableUKey22230:[],
            },
            tableimgData: [],
            tablefileData:[],
            file_url: Config.dev_url,
            basic_url: Config.dev_url,
            assetData:[],
            page: {
                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            },
            page2: {
                currentPage: 1,
                pageSize: 10,
                totalCount: 0
            },
            gcname:"",
						fzr:"",
						zxr:"",
            bdzname:"",
            plantdeptid:[],
            deptid:[],
            DEFECTTYPE:[],
            DEFECTLEVEL:[],
            ASSETNUM:[],
            frequnit:[],
            plantype:[],
            assetplantype:[],
            chooseParam: {},
						deptidchooseParam: {
                title: "变电站信息",
                isMulti: false,
                searchBtn: true,
                tableHeader: [
                    {
                        propName: "id",
                        labelName: "变电站编号",
                        targetAttribute: "deptid",
                        isSearch: 'no',
                        isShow: 'no'
                    },
                    {
                        propName: "fullname",
                        labelName: "变电站名称",
                        targetAttribute: "dept_fullname",
                        isSearch: 'yes',
                        isShow: 'yes',
                        width: '45%'
                    }
                ],
                search: [],
                url: "/api-user/depts?depttype=4",
                method: "get"
            },
            gcchooseParam: {
                title: "工厂信息",
                isMulti: true,
                searchBtn: true,
                tableHeader: [
                    {
                        propName: "fullname",
                        labelName: "工厂名称",
                        targetAttribute: "fullname",
                        isSearch: 'yes',
                        isShow: 'yes',
                        width: '35%'
                    },
                ],
                search: [],
                url: "/api-user/depts?page=1&limit=10&depttype=3",
                method: "get" 
            },
            bdzchooseParam: {
                title: "变电厂信息",
                isMulti: true,
                searchBtn: true,
                tableHeader: [
                    {
                        propName: "fullname",
                        labelName: "变电厂名称",
                        targetAttribute: "fullname",
                        isSearch: 'yes',
                        isShow: 'yes',
                        width: '35%'
                    },
                ],
                search: [],
                url: "/api-user/depts?page=1&limit=10&depttype=4",
                method: "get" 
            },
						zxrchooseParam: {
                title: "执行人信息",
                isMulti: true,
                searchBtn: true,
                tableHeader: [
                    {
                        propName: "name",
                        labelName: "执行人名称",
                        targetAttribute: "id",
                        isSearch: 'yes',
                        isShow: 'yes',
                        width: '35%'
                    },
                ],
                search: [],
                url: "/api-apps/appCustom/getUserAndGroup",
                method: "get" 
            },
            assetchooseParam: {
                title: "设备信息",
                isMulti: true,
                searchBtn: true,
                tableHeader: [
                    {
                        propName: "ASSETNUM",
                        labelName: "设备编号",
                        targetAttribute: "ASSETNUM",
                        isSearch: 'yes',
                        isShow: 'yes',
                        width: '25%'
                    },
                    {
                        propName: "DESCRIPTION",
                        labelName: "设备编号",
                        targetAttribute: "DESCRIPTION",
                        isSearch: 'yes',
                        isShow: 'yes',
                        width: '25%'
                    },
                    {
                        propName: "LOCATION",
                        labelName: "位置",
                        targetAttribute: "LOCATION",
                        isSearch: 'yes',
                        isShow: 'yes',
                        width: '25%'
                    },
                    {
                        propName: "SITEID",
                        labelName: "地点",
                        isSearch: 'yes',
                        targetAttribute: "SITEID",
                        isShow: 'yes',
                        width: '25%'
                    }
                ],
                search: [],
                url: "/api-apps/app/assetApp",
                method: "post"
            },
            routeApp:{
              title: "设备组",
                isMulti: true,
                searchBtn: true,
                tableHeader: [
                    {
                        propName: "ROUTE",
                        labelName: "设备组",
                        isSearch: 'yes',
                        targetAttribute: "ROUTE",
                        isShow: 'yes',
                        width: '25%'
                    }
                ],
                search: [],
                url: "/api-apps/app/routeApp",
                method: "post"
            },
            peoplechooseParam: {
                title: "人员信息",
                isMulti: true,
                searchBtn: true,
                tableHeader: [
                    {
                        propName: "id",
                        labelName: "用户名",
                        targetAttribute: "LEADER",
                        isSearch: 'no',
                        isShow: 'no',
                        width: '35%'
                    },
                    {
                        propName: "roleName",
                        labelName: "角色",
                        // targetAttribute: "SUPERVISOR",
                        isSearch: 'no',
                        isShow: 'yes',
                        width: '30%'
                    },
                    {
                        propName: "nickname",
                        labelName: "姓名",
                        targetAttribute: "LEADERname",
                        isSearch: 'yes',
                        isShow: 'yes',
                        width: '35%'
                    }
                ],
                search: [],
                url: "/api-user/users",
                method: "get"
            },
						peoplechooseParam2: {
                title: "人员信息",
                isMulti: true,
                searchBtn: true,
                tableHeader: [
                    {
                        propName: "id",
                        labelName: "用户名",
                        targetAttribute: "OPERATOR",
                        isSearch: 'no',
                        isShow: 'no',
                        width: '35%'
                    },
                    {
                        propName: "roleName",
                        labelName: "角色",
                        // targetAttribute: "SUPERVISOR",
                        isSearch: 'no',
                        isShow: 'yes',
                        width: '30%'
                    },
                    {
                        propName: "nickname",
                        labelName: "姓名",
                        targetAttribute: "OPERATORname",
                        isSearch: 'yes',
                        isShow: 'yes',
                        width: '35%'
                    }
                ],
                search: [],
                url: "/api-user/users",
                method: "get"
            },
            signStr:'',
        }
    },
    computed: {
        //当前登录人信息
        currentuser() {
            return this.$store.state.userInfo
        }
    },
    methods:{
			changecascader(row,index){
				console.log(row)
				console.log(index)
				this.$refs.myCascader.dropDownVisible = false
			},
			updateChooseData4(data){
            this.bdzname = data[0].fullname
            this.dataInfo.deptid = data[0].id
            console.log(this.dataInfo.deptid)
            this.$forceUpdate()
            this.$set(this.dataInfo)
        },
				updateChooseData5(data){
            
            this.dataInfo.OPERATORname = data[0].nickname
            this.dataInfo.OPERATOR = data[0].id
            
            this.$forceUpdate()
            this.$set(this.dataInfo)
        },
        //添加计划
        addplant(){
          let a = uuidv1()
          this.tableData.push({fakeid:a})
          this.tableDataid = [a]
        },
        getassetplantype(){
          var url = this.basic_url + '/api-user/dicts/findByCode?code=assetplantype'
            this.$axios.get(url).then((res) => {
              this.assetplantype = res.data.subDicts
            })
        },
        gettype(){
          var url = this.basic_url + '/api-user/dicts/findByCode?code=planttype'
            this.$axios.get(url).then((res) => {
              this.plantype = res.data.subDicts
            })
        },
        getfrequnit(){
          var url = this.basic_url + '/api-user/dicts/findByCode?code=frequnit'
            this.$axios.get(url).then((res) => {
              this.frequnit = res.data.subDicts
							for(let index in this.frequnit){
								if(this.frequnit[index].name == '年'){
									this.frequnit.splice(index, 1); 
								}
							}
            })
        },
        delAssetData(index, row) {
            if (!!row.id) {
                var url = this.basic_url + '/api-apps/app/defectApp/tb_asset_workorder/' + row.id
                this.$axios.delete(url).then((res) => {
                if (res.data.resp_code == 0) {
                    this.getAssetInfo()
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                } else {
                    this.$message({
                        message: res.data.resp_msg,
                        type: "warning"
                    });
                }
                })
            } else {
                this.assetData.splice(index, 1)
            }
        },
        updateChooseData(data){
						console.log(data)
            this.dataInfo.ASSETDESC = ""
            this.dataInfo.ASSETDESC = data[0].DESCRIPTION
            this.dataInfo.ASSETNUM = ""
            this.dataInfo.ASSETNUM = data[0].ASSETNUM
            this.$forceUpdate()
            this.$set(this.dataInfo)
						console.log(this.dataInfo)
        },
        updateChooseData2(data){
            this.dataInfo.ROUTE = data[0].ROUTE
            this.$forceUpdate()
            this.$set(this.dataInfo)
            
        },
        updateChooseData3(data){
						this.dataInfo.LEADERname = data[0].nickname
            this.dataInfo.LEADER = data[0].id

            this.$forceUpdate()
            this.$set(this.dataInfo)
        },
        clearInput(data, prop) {
            data[prop] = '';
            if(prop == 'user2_nickname'){
                data.user2_nickname = ''
                data.SUPERVISOR = ''
            }else if(prop == 'user1_nickname'){
                data.user1_nickname = ''
                data.LEADd = ''
            }
        },
        choose(target, chooseParam ,item) {
            console.log(target)
						console.log(chooseParam)
            for (var i = 0; i < chooseParam.tableHeader.length; i++) {
                chooseParam.tableHeader[i].targetObject = target;
            }
            this.chooseParam = chooseParam;
            this.$refs.choose.getListData("new", this.chooseParam);
        },
        choose2(target, chooseParam ,item) {
            
            for (var i = 0; i < chooseParam.tableHeader.length; i++) {
                chooseParam.tableHeader[i].targetObject = target;
            }
            this.chooseParam = chooseParam;
            this.$refs.sb.getListData("new", this.chooseParam);
        },
        choose3(target, chooseParam ,item) {
            // console.log(data)
            
            for (var i = 0; i < chooseParam.tableHeader.length; i++) {
                chooseParam.tableHeader[i].targetObject = target;
            }
            this.chooseParam = chooseParam;
            this.$refs.fzr.getListData("new", this.chooseParam);
        },
				choose5(target, chooseParam ,item) {
            // console.log(data)
            
            for (var i = 0; i < chooseParam.tableHeader.length; i++) {
                chooseParam.tableHeader[i].targetObject = target;
            }
            this.chooseParam = chooseParam;
            this.$refs.zxr.getListData("new", this.chooseParam);
        },
				choose4(target, chooseParam ,item) {
            
            for (var i = 0; i < chooseParam.tableHeader.length; i++) {
                chooseParam.tableHeader[i].targetObject = target;
            }
            this.chooseParam = chooseParam;
            this.$refs.bdz.getListData("new", this.chooseParam);
        },
        updateChoose(data) {
						console.log(data)
            this.$forceUpdate()
            this.$set(this.dataInfo)
        },
        saveBtn(num){
							this.dataInfo.ASSETNUM = this.$refs.myCascader.getCheckedNodes()[0].label
							for(let item of this.tableData){
								delete item.fakeid
							}
							this.dataInfo.ISALLCHANGE = num
							// this.dataInfo.PMMONTH = this.PMMONTH

							this.$refs.tableForm.validate((valid) => {
									if (valid) {
											var url = this.basic_url + "/api-apps/app/pmApp/addOrEdit"
											this.$axios.post(url, this.dataInfo).then(res => {
												if(this.type == 1){
													this.$message({
														message: "添加成功",
														type: "success"
													});
												}else{
													this.$message({
														message: "修改成功",
														type: "success"
													});
												}
												
											})
									}
							})
							this.dialogVisible = false
							this.$emit("shuaxin")
						
        },
        view(str,data,date,plant){
            this.tableData = []
            this.tableDataid = []
            
            this.getfrequnit()
            this.gettype()
            this.getassetplantype()
						this.getAssettypeTree()
            this.getPlantdeptid()
            this.getDeptid()
            // this.getASSETNUM()
            this.getDEFECTTYPE()
            this.getDEFECTLEVEL()
            this.signStr = str
            this.dialogVisible = true
						this.PMMONTH = date

						console.log(date)
						console.log(str)
            if(str == 'edit'){
								console.log(data)
								this.type = 2				
								this.dataInfo = data
								this.dataInfo.LEADERname =  data.lead_nickname
								this.dataInfo.OPERATORname =  data.contact_nickname
								this.dataInfo.ASSETTYPE = parseInt(this.dataInfo.ASSETTYPE)
								this.dataInfo.createtime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
                this.dataInfo.createuserdesc = this.currentuser.nickname
            }else if(str == 'cuwarningadd'){
							
                 this.dataInfo = {
                    WONUM:'',
                    DESCRIPTION:'',
                    WORKTYPE:'',
                    WOPRIORITY:'',
                    status:'等待核准',
                    STATUSDATE:'',
                    createuserdesc:'',
                    createtime:'',
                    WARNINGNUM:this.$store.state.detailInfo.WARNINGNUM,
                    TableUKey15440:[],
                },
                this.dataInfo.createtime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
                this.dataInfo.createuserdesc = this.currentuser.nickname
            }else{
								this.type = 1
                this.disable = false
                console.log(888888)
                this.dataInfo = {
                    
                },
                this.dataInfo.createtime = this.$moment().format("YYYY-MM-DD HH:mm:ss")
                this.dataInfo.createuserdesc = this.currentuser.nickname
								this.dataInfo.deptid = 14
            }
        },
        getAssetInfo(){
            var url = this.basic_url + '/api-apps/app/defectApp/object?object=tb_asset_workorder'
            var data = {
                page:this.page2.currentPage,
                limit:this.page2.pageSize,
                WONUM_wheres:this.dataInfo.WONUM
            }
            this.$axios.post(url,data).then((res)=>{
                if (res.status == 200) {
                    this.page2.totalCount = res.data.count
                    this.dataInfo.TableUKey15440 = res.data.data
                }
            })
        },
        //获取创建人描述
        getcreateuserdesc() {
            if (!!this.dataInfo.createuser) {
                var url = this.basic_url + '/api-user/users/' + this.dataInfo.createuser
                this.$axios.get(url).then((res) => {
                    this.$set(this.dataInfo, 'createuserdesc', res.data.nickname)
                })
            }
        },
        uploadTip() {
            if (!this.dataInfo.id) {
                this.$message.warning('请先保存当前数据之后再上传')
            }
        },
        //上传文件
        uploadfile() {
            if (document.getElementById('excelFileAssetInfo').value == '') {
                return;
            }
            var formData = new FormData();
            var loading;
            loading = Loading.service({
                fullscreen: true,
                text: '拼命上传中...',
                background: 'rgba(F,F, F, 0.8)'
            });
            for (var i = 0; i < document.getElementById('excelFileAssetInfo').files.length; i++) {
                formData.append('fileList', document.getElementById('excelFileAssetInfo').files[i]);
            }
            var url = this.file_url + '/file-center/file/uploads?appname=defectApp'
            + '&appid=49'
            + '&recordid=' + this.dataInfo.id
            + '&userid=  ' + this.currentuser.id
            + '&username=' + this.d.username
            + '&deptid=' + this.currentuser.deptid
            + '&deptfullname=' + this.currentuser.deptName
            // + '&encryption=0'  //不加密
            console.log(this.dataInfo.id)
            console.log(this.currentuser.id)
            console.log(this.d.username)
            console.log(this.currentuser.deptid)
            console.log(this.currentuser.deptName)
            console.log(formData, '上传的文件')
            this.$axios.post(url, formData).then((res) => {
                console.log(res, '上传文件结果')
                loading.close();
                document.getElementById('excelFileAssetInfo').value = ''
                if (res.data.code == 0) {
                    this.$message({
                        message: '文件已成功上传至服务器',
                        type: 'success'
                    });
                    this.getImgData()
                } else {
                    this.$message({
                        message: res.data.message,
                        type: 'error'
                    });
                }
            })
        },
        //下载
        download(row) {
            let a = row.filepath.substring(22)
            console.log(a)
            var url = this.basic_url
                +  a
                + '&userid=' + row.userid
                + '&username=' + row.username
                + '&deptid=' + row.deptid
                + '&deptfullname=' + row.deptfullname;
            var params = {
                access_token: this.getCookie("access_token")
            };
            var downurl = [url, $.param(params)].join('&');
            window.open(downurl);
        },
        del(row,str) {
            console.log(row)
            this.tableData.splice(row.$index,1)
            // var url = this.file_url + '/file-center/file/logicDeleteFile/' + row.fileid;
            // this.$confirm("确定删除此数据吗", "提示", {
            //     confirmButtonText: "确定",
            //     cancelButtonText: "取消"
            // }).then(() => {
            //     this.$axios.delete(url, {}).then((res) => {
            //         console.log(res, '删除结果')
            //         if (res.data.code == 1) {
            //             this.$message({ message: '删除成功', type: 'success' });
            //         } else {
            //             this.$message({ message: '删除失败', type: 'warning' });
            //         }
            //         if(str == 'file'){
            //             this.getFileData()
            //         }else{
            //             this.getImgData();
            //         }
            //     })
            // })
        },
        sizeChange2(val) {
            this.page2.pageSize = val;
            this.getAssetInfo();
        },
        currentChange2(val) {
            this.page2.currentPage = val;
            this.getAssetInfo();
        },
        sizeChange(val) {
            this.page.pageSize = val;
            this.getFileData();
        },
        currentChange(val) {
            this.page.currentPage = val;
            this.getFileData();
        },
        //获取文件列表
        getFileData() {
            var url = this.file_url + '/file-center/file/fileList';
            if (!this.dataInfo.id) {
                return
            }
            var data = {
                appname: 'defectApp',
                recordid: this.dataInfo.id,
                page: 1,
                size: 1000,
            }
            this.$axios.post(url, data).then((res) => {
                console.log(res, '获取文件信息')
                this.tablefileData = res.data.fileList
            })
        },
        getPlantdeptid(){
            var url = this.basic_url + '/api-user/depts?page=1&limit=10&depttype=3';
            this.$axios.get(url).then((res) => {
              console.log(res)
                console.log(res.data.data)
                this.plantdeptid = res.data.data;
            });
        },
				getAssettypeTree(){
            var url = this.basic_url + '/api-apps/appCustom/getAssettypeTree';
            this.$axios.get(url).then((res) => {
              this.options = res.data
            });
        },
        getDeptid(){
            var url = this.basic_url + '/api-user/depts?page=1&limit=10&depttype=4';
            this.$axios.get(url).then((res) => {
              console.log(res)
                this.deptid = res.data.data;
            });
        },
        getASSETNUM(){
            var url = this.basic_url + '/api-apps/app/assetApp';
            this.$axios.post(url,{"page":1,"limit":10}).then((res) => {
              console.log(res)
              for(let ltem of res.data.data){
                this.ASSETNUM.push(ltem.ASSETNUM)
                console.log(ltem)
              }
              console.log(this.ASSETNUM)
            });
        },
        getDEFECTTYPE(){
            var url = this.basic_url + '/api-user/dicts/findByCode?code=DEFECTTYPE';
            this.$axios.get(url).then((res) => {
              
                this.DEFECTTYPE   = res.data.subDicts;
            });
        },
        getDEFECTLEVEL(){
            var url = this.basic_url + '/api-user/dicts/findByCode?code=DEFECTLEVEL';
            this.$axios.get(url).then((res) => {
                
                this.DEFECTLEVEL = res.data.subDicts;
            });
        },
    },
    mounted(){
      
    }
}
</script>

<style scoped>
.viewerDoms{
  width: 100%;
  height: 240px;
  overflow: scroll;
}
.viewerDoms img{
  margin: 10px;
  border: 1px dashed #ccc;
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 10px;
  width: 200px;
  height: 200px;
}
.imgsItem{
   position: relative;
   width: 220px;
   height: 220px;
   float: left;
}
.uploadBtns {
  text-align: right;
  margin: 10px;
}
.pageLeft {
  float: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
#file button {
  position: relative;
}
.a-upload input {
  position: absolute;
  font-size: 0px;
  font-size: 20px \9;
  left: -15px;
  top: -10px;
  height: 30px;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: pointer;
  width: 70px;
  cursor: pointer;
}
.upload-btn {
  color: #fff;
  background-color: #286090;
  border-radius: 4px;
  padding: 4px 10px;
  width: 85px;
  height: 34px;
  line-height: 28px;
  border: none;
  cursor: pointer;
}
</style>
